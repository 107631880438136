
let constants = {
  URL_SERVER_AUTH: '/server/auth',
  URL_SERVER_USERS: '/server/users',

  URL_SERVER_GQL: '/api/graphql',

  STATUS_OK: 200,
  STATUS_CREATED: 201,
  STATUS_ACCEPTED: 202,
  STATUS_UNAUTHORIZED: 401,

  METHOD_GET: 'GET',
  METHOD_POST: 'POST',
  METHOD_PUT: 'PUT',
  METHOD_DELETE: 'DELETE',

  DIRECTORY: 'directory',
  
  EXT_DOC: 'doc',
  EXT_DOCX: 'docx',
  EXT_GIF: 'gif',
  EXT_JPEG: 'jpeg',
  EXT_JPG: 'jpg',
  EXT_MIDI: 'midi',
  EXT_MP3: 'mp3',
  EXT_MP4: 'mp4',
  EXT_PDF: 'pdf',
  EXT_PNG: 'png',
  EXT_PPT: 'ppt',
  EXT_PPTX: 'pptx',
  EXT_PUB: 'pub',
  EXT_RAR: 'rar',
  EXT_TXT: 'txt',
  EXT_VSD: 'vsd',
  EXT_WAV: 'wav',
  EXT_XLS: 'xls',
  EXT_XLSX: 'xlsx',
  EXT_ZIP: 'zip',

  JSON: 'json',
  APPLICATION_JSON: 'application/json',

  NA: 'N/A',
  HOME: 'HOME',

  TTX_CLOUD_PROTOCOOL: 'ttx-cloud-protocol',
  ACCESS_TOKEN_WS: 'access_token_ws',

  EVENT_COPY_FILE: 'copyFile',
  EVENT_MOVE_FILE: 'moveFile',
  EVENT_COPY_SHARED_FILE: 'copySharedFile',
  EVENT_MOVE_SHARED_FILE: 'moveSharedFile',

  MESSAGE_ERROR: 'Ocurrió un error al solicitar la información',
  MESSAGE_ERROR_WS: 'Ocurrío un error de conexión',

  CHUNK_256KB: 256000,

  LIMIT_FOR_RECONNECTION: 3,

  ENTER: 13,

  ASC: 1,
  DESC: 2,
};

export default constants;