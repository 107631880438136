import React, { Component } from 'react';
import { isArray } from 'underscore';

import constants from './constants';

import './LogIn.css'

const $ = window.jQuery

class LogIn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    };

    this.usernameRef = React.createRef();
    this.pwdRef = React.createRef();
    this.hashRef = React.createRef();
  }

  logIn(json) {
    let self = this;

    let xhr = $.ajax({
      url: `${constants.URL_SERVER_GQL}/authentication`,
      type: constants.METHOD_POST,
      contentType: constants.APPLICATION_JSON,
      data: JSON.stringify(json),
    });

    xhr.done((res, status, response) => {
      // console.log(res, response.status);

      if (response.status === constants.STATUS_OK) {
        let data = res;
        if (isArray(data)) {
          let message = data[0].message;
          if (message) {
            $('#toast-remember-message').toast('show');
            $('#remember-message').html(message);

            self.setState({ disabled: false });
            return;
          }
        }

        window.location.href = '/';

      } else if (response.status === constants.STATUS_ACCEPTED) {
        let data = res;
        if (isArray(data)) {
          let message = data[0].message;
          if (message) {
            $('#toast-remember-message').toast('show');
            $('#remember-message').html(message);
          }
        }

      } else {
        $('#toast-remember-message').toast('show');
        $('#remember-message').html(constants.MESSAGE_ERROR);
      }

      self.setState({ disabled: false });
    });

    xhr.fail((res, status, response) => {
      // console.log(res, response.status);

      let message = constants.MESSAGE_ERROR;

      if (res.responseJSON) {
        let json = res.responseJSON;
        message = json.message;
      }

      $('#toast-remember-message').toast('show');
      $('#remember-message').html(message);

      self.setState({ disabled: false });
    });
  }

  handleSubmit() {
    let self = this;

    let fn = (evt) => {
      evt.preventDefault();

      let username = self.usernameRef.current.value.trim();
      let password = self.pwdRef.current.value.trim();
      let hash = self.hashRef.current.value.trim();

      if (username === '') return;
      if (password === '') return;
      if (hash === '') return;

      let input = { email: username, password: password, hash: hash };

      let query = `
        mutation LogIn($input: LogIn!){
          logIn(input: $input) {
            id
            email
            name
            createdAt
            updatedAt
          }
        }
      `

      self.setState({ disabled: true }, () => {
        let o = { query: query, variables: { input: input }, operation: 'LogIn' };
        self.logIn(o);
      });
    };

    return fn;
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit()}>
        <div className="logointro">
          <img src="/static/images/logottx.svg" alt="Logo de TTx" />
        </div>
        <div className="form-group">
          <label htmlFor="input-username">Usuario / Correo electrónico</label>
          <input type="text" className="form-control" id="input-username" placeholder="Usuario / Correo electrónico" autoFocus={true} ref={this.usernameRef} />
        </div>
        <div className="form-group">
          <label htmlFor="input-password">Contraseña</label>
          <input type="password" className="form-control TTxCloud-input-password" id="input-password" placeholder="Contraseña" ref={this.pwdRef} />
        </div>
        <div className="form-group">
          <label htmlFor="input-key-in">Clave</label>
          <input type="text" className="form-control" id="input-key-in" placeholder="Clave" ref={this.hashRef} autocomplete="off" />
        </div>
        <button type="submit" className="btn btn_ttx" disabled={this.state.disabled}>Iniciar sesión</button>
        <p className="t_right TTxCloud-version"> CloudTTx v1.0.0 ™</p>
        <p>
          <a href="/signin" className="btn btn-link">Ir a registro</a>
        </p>
        <p>
          <a href="/remember" className="btn btn-link">Restablecer contraseña</a>
        </p>        
      </form>
    );
  }
}

export default LogIn;
