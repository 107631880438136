import React from 'react';
import ReactDOM from 'react-dom';

import './include/bootstrap'

import './index.css';
//import App from './App';
import LogIn from './LogIn';
//import SignIn from './SignIn';
//import Remember from './Remember';
//import Users from './users/Users';
//import Dashboard from './dashboard/Dashboard';
//import Profile from './Profile';
import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<Dashboard />, document.getElementById('content-main'));
//ReactDOM.render(<Profile />, document.getElementById('content-main'));
//ReactDOM.render(<Users />, document.getElementById('content-main'));
ReactDOM.render(<LogIn />, document.getElementById('content-main'));
//ReactDOM.render(<SignIn />, document.getElementById('content-main'));
//ReactDOM.render(<Remember />, document.getElementById('content-main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
